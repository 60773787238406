import React from "react";

import SocialLinks from "../../_molecules/sociallinks";
import EmailLink from "../../_atoms/emailLink";
import { Contact, SectionHeader } from './sidebar-styles';

import {
    Root,
    ImageWrapper,
    GatsbyImg as Img,
    Blurb,
    Text,
    Links
} from "./sidebar-styles";

const Sidebar = ({ pic, blurb, socialLinks, email, contact }) => (
    <Root>
        <ImageWrapper>
            <Img
                fixed={pic.src.childImageSharp.fixed}            
                alt={pic.name}
            />
        </ImageWrapper>
        <Blurb>
            <SectionHeader>About Chris</SectionHeader>
            <Text dangerouslySetInnerHTML={{ __html:blurb.html }} />
        </Blurb>
        <Links>
            <SocialLinks items={socialLinks} />
        </Links>
        <Contact id="contact-section">
            <SectionHeader>Contact</SectionHeader>
            <Text dangerouslySetInnerHTML={{ __html:contact.html }} />
            <EmailLink {...email} />
        </Contact>
    </Root>
);

export default Sidebar;