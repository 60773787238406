import styled, { createGlobalStyle } from "styled-components";
import config from "../components/config";
import { bpPhabletTablet, backgroundPrimary, textSecondary, spacingHalf, backgroundSecondary, bpMobilePhablet } from "../styles/_theme-variables";
import { transitionBorder, transitionBackgroundColor } from "../styles/_mixins";

const { barHeightMobile, barHeightDesktop } = config;
const baseScale = 100;
const mobileHeaderHeight = 4.6;
const bpDesktop = 50;

export const GlobalStyle = createGlobalStyle`
    html {
        font-size: ${baseScale}%;
        /* filter: grayscale();         */
    }

    body {
        background: ${backgroundPrimary};
        ${transitionBackgroundColor}
    }
`;

export const Root = styled.div`
    margin-top: ${barHeightMobile}px;
    @media screen and (min-width: ${bpDesktop}em) {
        margin-top: ${barHeightDesktop}px;
    }

    min-height: 100vh;

    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto auto;
    grid-template-areas:
    "Main"
    "Sidebar"
    "Footer";
`;

export const HeaderWrapper = styled.div`
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;

    @media screen and (max-width: ${bpMobilePhablet}) {                
        min-height: ${mobileHeaderHeight}rem;
    }
`;

export const SidebarWrapper = styled.div`
    grid-area: Sidebar;
    background-color: ${backgroundSecondary};
    padding: ${spacingHalf};
`;

export const MainWrapper = styled.div`
    grid-area: Main;
`;

export const Main = styled.main`
    height: auto;
    margin: 0 auto;    
    padding-top: 0;
`;

export const FooterWrapper = styled.div`
    grid-area: Footer;
    ${transitionBorder};

    @media screen and (min-width: ${bpPhabletTablet}) {      
      border-top: 1px solid ${textSecondary};
    }
`;