import styled, { css } from "styled-components";
import { articleFont } from "../../styles/_mixins";
import { spacingQuarter, primaryColor } from '../../styles/_theme-variables';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const iconLinkColor = primaryColor;

export const Root = styled.div`
    display: flex;
    align-items: center;
`;

const IconLinkStyles = css`
    margin-right: ${spacingQuarter};
    width: 1.6rem;
    color: ${iconLinkColor};
`

export const Icon = styled(FontAwesomeIcon)`
    ${IconLinkStyles}
`;

export const Selector = styled.select`
    ${articleFont}
    border: none;
    background-color: transparent;
    color: ${iconLinkColor};
    cursor: pointer;
`;
