import React from 'react'

const defaultState = {
  isDarkMode: false,
  themeKey: null,
  toggleDark: () => {},
  setThemeKey: () => {}
}

const ThemeContext = React.createContext(defaultState);

// Getting dark mode information from OS!
// You need macOS Mojave + Safari Technology Preview Release 68 to test this currently.
const supportsDarkMode = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches === true

class ThemeProvider extends React.Component {
  state = {
    isDarkMode: false,
    themeKey: 'blank'
  }

  toggleDark = dark => {
    localStorage.setItem('isDarkMode', JSON.stringify(dark))
    this.setState({ isDarkMode: dark })
  }

  setThemeKey = key => {
    localStorage.setItem('themeKey', JSON.stringify(key))
    this.setState({ themeKey: key });
  }

  componentDidMount() {
    // Getting dark mode value from localStorage!
    const lsDark = JSON.parse(localStorage.getItem('isDarkMode'))
    if (lsDark !== null) {
      this.setState({ isDarkMode: lsDark })
    } else if (supportsDarkMode()) {
      this.setState({ isDarkMode: true })
    }

    // Getting the theme key value from localStorage!
    const lsThemeKey = JSON.parse(localStorage.getItem('themeKey'))
    if (lsThemeKey !== null) {
        this.setState({ themeKey: lsThemeKey })
    } else {
        this.setState({ themeKey: 'default'})
    }
  }

  render() {
    const { children } = this.props
    const { isDarkMode, themeKey } = this.state
    return (
      <ThemeContext.Provider
        value={{
          isDarkMode,
          toggleDark: this.toggleDark,
          themeKey,
          setThemeKey: this.setThemeKey
        }}
      >
        {children}
      </ThemeContext.Provider>
    )
  }
}

export default ThemeContext

export { ThemeProvider }
