export const toggleNames = {
    SHOW_HELLO_WORLD: 'showHelloWorld',
    THEMEING: 'themeing',
    DARKMODE: 'darkmode',
    PROFILE: 'profile',
    FEEDS: 'feeds'
    // ... add more here
};
   
export const toggles = {
    // Try setting this to true
    [toggleNames.SHOW_HELLO_WORLD]: false,
    [toggleNames.THEMEING]: false,
    [toggleNames.DARKMODE]: true,
    [toggleNames.PROFILE]: true,
    [toggleNames.FEEDS]: true
    // ... add more here
};