import * as React from 'react';
import { ApplicationState } from './application-state.interface';
import rootReducer from './root.reducer';
import { initialState } from './initial-state';

interface StateProviderProps {
    children: any;
}

interface IStateContext {
    state: ApplicationState;
    dispatch: ({type}:{type:string}) => void;
}

export const GlobalStore = React.createContext({} as IStateContext);

const asyncer = (dispatch: any, state: ApplicationState) => (action: any) =>
    typeof action === 'function' ? action(dispatch, state) : dispatch(action);

export function StateProvider(props: StateProviderProps) {
    const [state, dispatchBase] = React.useReducer(rootReducer, initialState);

    const dispatch = React.useCallback(asyncer(dispatchBase, state), [])

    return (
        <GlobalStore.Provider value={{ state, dispatch }}>
            { props.children }
        </GlobalStore.Provider>
    )
}