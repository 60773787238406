import React from "react"
import { Highlight, Snippet } from "react-instantsearch-dom"
import { Link } from "gatsby"
import { Root, Item } from './hit-components-styles';

export const PageHit = clickHandler => ({ hit }) => (
    <div>
      <Link to={hit.slug} onClick={clickHandler}>
        <h4>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </h4>
      </Link>
      <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    </div>
  )

  export const PostHit = clickHandler => ({ hit }) => (
    <Root>
      <Link to={`/blog/` + hit.slug} onClick={clickHandler}>
        <Item>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Item>
      </Link>
    </Root>
  )

  export const ProjectHit = clickHandler => ({ hit }) => (
    <Root>
      <Link to={`/portfolio/` + hit.slug} onClick={clickHandler}>
        <Item>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Item>
      </Link>
    </Root>
  )
  export const ResourceHit = clickHandler => ({ hit }) => (
    <Root>
      <a href={hit.url} target="_blank" onClick={clickHandler}>
        <Item>
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Item>
      </a>
    </Root>
  )