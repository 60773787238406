import React, { useEffect, useState } from "react";
import { FeatureToggle } from "react-feature-toggles/lib";
import ThemeContext from "../../../context/theme-context";

import {
    Root,
    MobileControls,
    Text,
    Navigation,
    SearchResults,
    Controls,
    Settings,
    TextContainer,
    HeaderText,
    SubheaderText,
    BurgerButton,
    SearchIcon,
    SettingsIcon,
    ControlButton,
    Link,
    Setting,
    SettingsHeader
} from './menu-styles';
import { resolveIcon } from "../../../framework/icon-resolver";
import { toggleNames } from "../../../features";
import DarkModeToggle from "../darkmode-toggle";
import ThemeSelector from "../../theme-selector";
import { themes } from "../../../styles/themeResolver";

const { THEMEING, DARKMODE } = toggleNames;

import Search from '../../_molecules/search/index';
import NavMenu from "../../_molecules/navmenu";
import ScrollBar from '../../_molecules/scroll-bar/index';
import withApplicationState from '../../_hocs/with-application-state';
import { ApplicationState } from '../../../providers/application-state.interface';
import { isExpandedActionTypes } from '../../../providers/reducers/is-expanded.reducer';
import { userModeActionTypes } from "../../../providers/reducers/user-mode.reducer";
import { ITrackingService } from '../../../services/tracking.service';

export interface MenuProps {
    header: string;
    subheader: string;
    navigation: any;
    state: ApplicationState;
    trackingService: ITrackingService,
    dispatch: ({ type }: { type: string; payload?: any; }) => void
}

const Menu = (props: MenuProps) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    const toggleMenu = () => {
        if (props.state.userMode === 'default') {   
            props.dispatch({ type: props.state.isExpanded
                ? isExpandedActionTypes.COLLAPSE
                : isExpandedActionTypes.EXPAND
            })
        } else {
            props.dispatch({ type: userModeActionTypes.SET_USER_MODE, payload: 'default' })
            props.dispatch({ type: isExpandedActionTypes.COLLAPSE })
        }        
    };

    const toggleSearch = (searchModeEnabled: boolean) => {
        props.trackingService.TrackEvent({
            name: 'Search Toggled',
            payload: {
                enabled: searchModeEnabled
            }
        });
        props.dispatch({ type: userModeActionTypes.SET_USER_MODE, payload: searchModeEnabled ? '' : 'search' });
    }

    const toggleSettings = (settingModeEnabled: boolean) => {
        props.trackingService.TrackEvent({
            name: 'Settings Toggled',
            payload: {
                enabled: settingModeEnabled
            }
        });
        props.dispatch({ type: userModeActionTypes.SET_USER_MODE, payload: settingModeEnabled ? '' : 'settings' });
    }

    const searchModeEnabled = props.state.userMode === 'search';
    const settingsModeEnabled = props.state.userMode === 'settings';

    const getDocHeight = () => {
        return Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        );
    }

    const calculateScrollDistance = () => {
        const scrollTop = window.pageYOffset; // how much the user has scrolled by
        const winHeight = window.innerHeight;
        const docHeight = getDocHeight();
    
        const totalDocScrollLength = docHeight - winHeight;
        const newPosition = Math.floor(scrollTop / totalDocScrollLength * 100)
    
        setScrollPosition(newPosition);
    }

    const listenToScrollEvent = () => {
        document.addEventListener("scroll", () => {
            requestAnimationFrame(() => {
                calculateScrollDistance();
            });
        });
    }

    useEffect(() => {
        listenToScrollEvent();
    }, []);

    return (
        <ThemeContext.Consumer>
      {
        theme => (
        <>
            <Root
                isExpanded={props.state.isExpanded}
                searchModeEnabled={searchModeEnabled}
                settingsModeEnabled={settingsModeEnabled}
                data-testid="menu"
            >
                <MobileControls>
                    <BurgerButton
                        aria-label="menu-button"
                        isExpanded={
                            (props.state.isExpanded || searchModeEnabled || settingsModeEnabled)
                        }
                        onClick={toggleMenu}    
                    >
                        <div />
                        <div />
                        <div />
                    </BurgerButton>
                </MobileControls>

                <Navigation
                    searchModeEnabled={searchModeEnabled}
                    settingsModeEnabled={settingsModeEnabled}
                >
                    <NavMenu items={props.navigation}></NavMenu>
                </Navigation>

                { searchModeEnabled &&
                    <SearchResults>
                        <SettingsHeader>Search { props.state.searchQuery && `"${props.state.searchQuery}"` }</SettingsHeader>
                        <Search cancelSearch={() => toggleSearch(searchModeEnabled)} trackingService={props.trackingService} />
                    </SearchResults>
                }

                <Text>
                    <TextContainer>
                        <Link href="/" data-testid="menu-label">
                            <HeaderText>{ props.header }</HeaderText>
                            <SubheaderText>{ props.subheader }</SubheaderText>
                        </Link>
                    </TextContainer>
                </Text>

                { settingsModeEnabled &&
                    <Settings>
                        <SettingsHeader>Settings</SettingsHeader>
                        <Setting>
                            DarkMode { theme && <DarkModeToggle isDarkMode={theme.isDarkMode} toggleDark={theme.toggleDark} /> }              
                        </Setting>
                        <FeatureToggle featureName={THEMEING}>
                            <Setting>Theme: { theme && <ThemeSelector themeKey={theme.themeKey} setThemeKey={theme.setThemeKey} themes={themes} /> }</Setting>
                        </FeatureToggle>
                    </Settings>
                }
                <Controls>
                    <ControlButton aria-label="search-menu-button" onClick={() => toggleSearch(searchModeEnabled)} active={searchModeEnabled}>
                        <SearchIcon />
                    </ControlButton>
                    <ControlButton aria-label="settings-menu-button" onClick={() => toggleSettings(settingsModeEnabled)} active={settingsModeEnabled}>
                        <SettingsIcon icon={resolveIcon('settings')} />
                    </ControlButton>
                </Controls>
            </Root>
            <ScrollBar scroll={scrollPosition} />
        </>
        )
    }
    </ThemeContext.Consumer>
    );
};

export default withApplicationState(Menu);
