import { css } from "styled-components";
import config from '../components/config';

export const calculateFluidTypographySize = (
    minFontSize = 16,
    maxFontSize = 24,
    minScreenSize = 400,
    maxScreenSize = 800,
    fontUnit = 'px',
    viewportUnit = 'px'
) => css`
    font-size: calc(${minFontSize}${fontUnit} + (${maxFontSize} - ${minFontSize}) * (100vw - ${minScreenSize}${viewportUnit}) / (${maxScreenSize} - ${minScreenSize}));
`;

const calculateFontSizes = () => {
    const { minViewportWidth, maxViewportWidth } = config;

    const baseFont = 1; // working in rems - 1rem = 16px

    const mobileScale = 1.125; // major second
    const desktopScale = 1.250; // major third

    const mobileBody = baseFont;
    const desktopBody = baseFont;

    const bodyStart = mobileBody;
    const bodyEnd = mobileBody * 1.875;
    const smallPrintStart = bodyStart / mobileScale;
    const smallPrintEnd = bodyEnd / mobileScale;
    const header6Start = bodyStart * mobileScale;
    const header6End = bodyEnd * mobileScale;
    const header5Start = header6Start * mobileScale;
    const header5End = header6End * mobileScale;
    const header4Start = header5Start * mobileScale;
    const header4End = header5End * mobileScale;
    const header3Start = header4Start * mobileScale;
    const header3End = header4End * mobileScale;
    const header2Start = header3Start * mobileScale;
    const header2End = header3End * mobileScale;
    const header1Start = header2Start * mobileScale;
    const header1End = header2End * mobileScale;

    const smallPrintSize = css`${calculateFluidTypographySize(smallPrintStart, smallPrintEnd, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const bodySize = css`${calculateFluidTypographySize(bodyStart, bodyEnd, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const header6Size = css`${calculateFluidTypographySize(header6Start, header6End, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const header5Size = css`${calculateFluidTypographySize(header5Start, header5End, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const header4Size = css`${calculateFluidTypographySize(header4Start, header4End, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const header3Size = css`${calculateFluidTypographySize(header3Start, header3End, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const header2Size = css`${calculateFluidTypographySize(header2Start, header2End, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;
    const header1Size = css`${calculateFluidTypographySize(header1Start, header1End, minViewportWidth, maxViewportWidth, 'rem', 'em')}`;

    return {
        smallPrintSize,
        bodySize,
        header6Size,
        header5Size,
        header4Size,
        header3Size,
        header2Size,
        header1Size
    }
}

export const fluidFontSizes = calculateFontSizes();