import React from 'react';
import { GlobalStore } from '../../providers/state.provider';

export default function withApplicationState(Component: any) {
    return function WrapperComponent(props: any) {
        return (
            <GlobalStore.Consumer>
                {context => <Component {...props} state={context.state} dispatch={context.dispatch} />}
            </GlobalStore.Consumer>
        );
    }
}
