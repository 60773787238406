module.exports = [{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"showCaptions":true,"maxWidth":1280},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chris Wallace","short_name":"g33kchris.net","start_url":"/","background_color":"#1056be","theme_color":"#1056be","display":"minimal-ui","icon":"src/images/site-icon.png","cache_busting_mode":"query"},
    },{
      plugin: require('/home/vsts/work/1/s/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"iOo8QQAPdORMORslOlOnkBMR8fIeDxei","trackPage":false},
    },{
      plugin: require('/home/vsts/work/1/s/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
