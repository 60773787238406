import { 
    faWindowMaximize,
    faEnvelope,
    faCoffee,
    faPhone,
    faCode,
    faLink,
    faPaintBrush,
    faMoon,
    faLaptopCode,
    faChalkboardTeacher,
    faGlasses,
    faVideo,
    faImage,
    faCog,
    faBookReader
} from "@fortawesome/free-solid-svg-icons";

import {
    faLinkedin,
    faGithub,
    faTwitter,
    faCodepen,
    faNpm
} from "@fortawesome/free-brands-svg-icons"; 


export const resolveIcon = key => {
    const fontAwesomeIcons = {
        email: faEnvelope,
        linkedin: faLinkedin,
        website: faWindowMaximize,
        github: faGithub,
        telephone: faPhone,
        twitter: faTwitter,
        codepen: faCodepen,
        stackshare: faLink,
        codewars: faCode,
        theme: faPaintBrush,
        darkmode: faMoon,
        laptop: faLaptopCode,
        teacher: faChalkboardTeacher,
        glasses: faGlasses,
        npm: faNpm,
        codechallenges: faCode,
        video: faVideo,
        photo: faImage,
        settings: faCog,
        reading: faBookReader
    };

    return fontAwesomeIcons[key] || faCoffee;
};
