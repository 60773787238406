import React from "react";
import { resolveIcon } from "../../../framework/icon-resolver";

import {
    Root,
    Icon
} from "./emaillink-styles";

const EmailLink = ({ link, label, address }) => (
    <Root href={link}>
        <Icon
            icon={resolveIcon('email')}
            className="c-emailLink__icon"
        />
        { label }
    </Root>
);

export default EmailLink;