import styled, { css } from "styled-components";
import Img from 'gatsby-image';
import { articleFont, transitionColor, headerFontMixin } from "../../../styles/_mixins";
import { Link } from "gatsby";
import { fluidFontSizes } from '../../../styles/_calculations';
import { bpPhabletTablet, textPrimary, textSecondary } from "../../../styles/_theme-variables";

const bpXL = '111em';

const textColor = textPrimary;
const headerColor = textSecondary;

export const Root = styled.div`
    display: grid;            

    @media screen and (min-width: ${bpPhabletTablet}) {
        grid-template-columns: auto 3fr 1fr 2fr;
        grid-template-rows: auto;
        grid-template-areas:
        "Image Text Social Contact";
    }

    @media screen and (min-width: 30em) and (max-width: ${bpXL}) {
        grid-template-columns: 1fr auto auto;
        grid-template-rows: auto auto;
        grid-template-areas:
        "Image Text Text"
        "Image Social Contact";
    }

    @media screen and (max-width: 30em) {
        grid-template-columns: auto;
        grid-template-rows: auto auto auto auto;
        grid-template-areas:
        "Image"
        "Text"
        "Social"
        "Contact";
    }
`;

export const ImageWrapper = styled.div`
    grid-area: Image;

    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    ${fluidFontSizes.bodySize}
    margin-top: 1em;
    margin-bottom: 1em;
    max-width: 14rem;
`;

export const GatsbyImg = styled(Img)`
    border-radius: 50%;
`;

export const Blurb = styled.div`
    ${fluidFontSizes.bodySize}
    padding: 1em;
    grid-area: Text;
`;

export const Text = styled.div`
    color: ${textColor};
    ${articleFont};
    ${transitionColor};

    ${fluidFontSizes.bodySize}

    & p {
        margin-bottom: 1em;
    }
`;

export const Navigation = styled.ul`
    grid-area: Nav;
    margin: 1em;
    list-style: none;
    ${articleFont};
    ${fluidFontSizes.bodySize}
    margin-left: auto;
    margin-right: auto;
`;

export const NavigationItem = styled.li`
    display: inline-block;
    margin: 0 0.5em;
    margin-bottom: 0.5em;

    @media screen and (min-width: ${bpPhabletTablet}) {      
        text-align: left;
    }

    @media screen and (max-width: ${bpPhabletTablet}) {      
        text-align: center;
    }
`;

const LinkStyle = css`
    ${articleFont};
    text-decoration: none;
    display: inline-block;
    ${transitionColor};

    &:hover {
        text-decoration: underline;
    }
`;

export const GatsbyLink = styled(Link)`
    ${LinkStyle}
`

export const RegularLink = styled.a`
    ${LinkStyle}
`;

export const Links = styled.div`
    grid-area: Social;
    ${fluidFontSizes.bodySize}
    padding: 1em;
    display: flex;

    @media screen and (min-width: ${bpXL}) {
        justify-content: center;
    }
`;

export const Contact = styled.div`
    grid-area: Contact;
    ${fluidFontSizes.bodySize}
    padding: 1em;
`;

export const SectionHeader = styled.h4`
    ${headerFontMixin}
    ${fluidFontSizes.header4Size}
    color: ${headerColor};
    margin-bottom: 0.5em;
`