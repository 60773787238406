import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import {
    bpPhabletTablet,
    textSecondary,
    textPrimary
} from "../../../styles/_theme-variables";

const textColor = textPrimary;
const linkColor = textSecondary; 

import { articleFont, transitionColor } from "../../../styles/_mixins";
import { fluidFontSizes } from '../../../styles/_calculations';

export const Root = styled.footer`
    ${fluidFontSizes.smallPrintSize}
    padding: 1em;
    display: flex;

    @media screen and (min-width: ${bpPhabletTablet}) {
        flex-direction: row;
    }

    @media screen and (max-width: ${bpPhabletTablet}) {
        flex-direction: column;
    }
`;

export const Left = styled.div`
    flex: 1;
    @media screen and (min-width: ${bpPhabletTablet}) {
        text-align: left;
    }

    @media screen and (max-width: ${bpPhabletTablet}) {
        text-align: center;
    }
`;

export const Right = styled.div`
    flex: 1;
    @media screen and (min-width: ${bpPhabletTablet}) {
        text-align: right;
    }

    @media screen and (max-width: ${bpPhabletTablet}) {
        text-align: center;
    }
`;

export const Text = styled.p`
    ${articleFont};
    color: ${textColor};
    ${transitionColor};

    & a {
        color: ${linkColor};
    }
`;

export const Markdown = styled(ReactMarkdown)`
    ${articleFont};
    color: ${textColor};
    ${transitionColor};

    & a {
        color: ${linkColor};
    }
`

export const ThemeSelectorWrapper = styled.div`
    margin-bottom: 1em;
`;