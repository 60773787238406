import styled from "styled-components";
import { spacingHalf, textPrimary, textSecondary } from "../../../styles/_theme-variables";
import { articleFont, transitionColor } from "../../../styles/_mixins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fluidFontSizes } from '../../../styles/_calculations';

export const Root = styled.ul`
    list-style: none;
`;

export const Item = styled.li``;

export const NavigationLink = styled.a`
    ${articleFont};
    margin-bottom: 0.5em;
    ${fluidFontSizes.bodySize}
    text-decoration: none;
    color: ${textPrimary};
    ${transitionColor};

    &:hover {
        color: ${textSecondary};
    }
`

export const Icon = styled(FontAwesomeIcon)`
    margin-right: ${spacingHalf};
    width: 1.6rem;
`;