/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import { Cookies } from "react-cookie-consent";

import { ThemeProvider } from './src/context/theme-context'
import Layout from './src/components/layout'
import { StateProvider } from './src/providers/state.provider';
import InstantSearchProvider from './src/providers/instantsearch.provider';

require("./src/styles/prism.css");

export const wrapRootElement = ({ element }) => (
  <StateProvider>
    <ThemeProvider>
      <InstantSearchProvider>
        {element}
      </InstantSearchProvider>
    </ThemeProvider>
  </StateProvider>
)

export const wrapPageElement = ({ element }) => (
  <Layout>
    { element }
  </Layout>
)

export function onRouteUpdate(){
  const consentCookie = Cookies.get('CookieConsent');
  if (consentCookie === 'true') {
    window.analytics && window.analytics.page();
  }  
};