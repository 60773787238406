import { StateAction } from '../state-action.interface';
export const searchFocusActionTypes = {
    SET_FOCUS: 'SET_FOCUS'
}

export default function searchFocusReducer(state: boolean = false, action: StateAction): boolean {
    switch(action.type) {
        case searchFocusActionTypes.SET_FOCUS: {
            return action.payload;
        }
        default:
            return state;
    }
}