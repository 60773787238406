import React from "react";
import { withTheme } from 'styled-components';
import { resolveIcon } from "../../../framework/icon-resolver";

import ToggleButton from 'react-toggle-button';
import { leftAccentColorLighter } from '../../../styles/_theme-variables';

import {
    Root,
    Icon
} from "./darkmode-toggle-styles";

const DarkModeToggle = ({ isDarkMode, toggleDark, theme }) => (
    <Root>
        <Icon
            icon={resolveIcon('darkmode')}
            style={{width: '0.8em'}}
        />
        <ToggleButton
            value={ isDarkMode }
            onToggle={value => toggleDark(!value)}
            colors={{
                activeThumb: {
                  base: theme.styles.color.leftAccentColorLighter,
                },
                inactiveThumb: {
                    base: theme.styles.color.leftAccentColorLighter,
                },
                active: {
                  base: theme.styles.color.primaryColor,
                  hover: theme.styles.color.primaryColor
                },
                inactive: {
                    base: theme.styles.color.primaryColor,
                    hover: theme.styles.color.primaryColor
                }
            }}
            inactiveLabelStyle={{
                color: theme.styles.color.leftAccentColorLighter,
                fontFamily: theme.styles.font.proseFont,
                fontSize: '1rem'
            }}
            activeLabelStyle={{
                color: theme.styles.color.leftAccentColorLighter,
                fontFamily: theme.styles.font.proseFont,
                fontSize: '1rem'
            }}
            activeThumbStyle={{
                boxShadow: 'none'
            }}
            inactiveThumbStyle={{
                boxShadow: 'none'
            }}
        />
    </Root> 
);

export default withTheme(DarkModeToggle);

