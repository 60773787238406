import merge from "lodash.merge";
import { transparentize, lighten, darken, desaturate } from "polished";
import { defaultThemeLight, defaultThemeDark } from "./_theme-default";
import { css } from "styled-components";

const basicBlack = `rgb(37, 37, 37)`;
const basicWhite = `rgb(255, 255, 255)`;
const primaryColor = basicBlack;
const secondaryColor = basicBlack;

const primaryColorLighter = lighten(0.1, primaryColor);
const primaryColorLightest = lighten(0.5, primaryColor);
const primaryColorLightestDesaturated = desaturate(1, primaryColorLightest);
const primaryColorDarker = darken(0.1, primaryColor);
const primaryColorDarkest = darken(0.3, primaryColor);

const secondaryColorLighter = lighten(0.1, secondaryColor);
const secondaryColorLightest = lighten(0.5, secondaryColor);
const secondaryColorDarker = darken(0.1, secondaryColor);
const secondaryColorDarkest = darken(0.5, secondaryColor);

const textPrimary = basicBlack;
const textPrimaryTransparent = transparentize(0.15, textPrimary);
const textSecondary = basicBlack;
const backgroundPrimary = basicWhite;
const backgroundSecondary = basicWhite;
const backgroundPrimaryTransparent = transparentize(0.15, backgroundPrimary);

const dmTextPrimary = basicWhite;
const dmBackgroundPrimary = basicBlack;
const dmBackgroundPrimaryTransparent = transparentize(0.15, dmBackgroundPrimary);
const dmSecondaryColor = basicWhite;
const dmSecondaryColorLighter = lighten(0.1, dmSecondaryColor);
const dmSecondaryColorLightest = lighten(0.5, dmSecondaryColor);
const dmSecondaryColorDarker = darken(0.1, dmSecondaryColor);
const dmSecondaryColorDarkest = darken(0.5, dmSecondaryColor);

const colorlessThemeLightOverrides = {
    color: {
        textPrimary,
        textPrimaryTransparent,
        textSecondary,  
        backgroundPrimary,        
        backgroundPrimaryTransparent,
        backgroundSecondary,
        primaryColor,
        primaryColorLighter,
        primaryColorLightest,
        primaryColorLightestDesaturated,
        primaryColorDarker,
        primaryColorDarkest,        
        secondaryColor,
        secondaryColorLighter,
        secondaryColorLightest,        
        secondaryColorDarker,
        secondaryColorDarkest
    },
    overrides: {
        headerRoot: css`
            background: ${basicWhite};
            border-bottom: ${basicWhite};
        `
    }
};

export const colorlessThemeLight = merge({}, defaultThemeLight, colorlessThemeLightOverrides);

const colorlessThemeDarkOverrides = {
    color: {
        backgroundPrimary: dmBackgroundPrimary,
        backgroundPrimaryTransparent: dmBackgroundPrimaryTransparent,
        backgroundSecondary: dmBackgroundPrimary,
        textPrimary: dmTextPrimary,
        textSecondary: dmTextPrimary,
        secondaryColorDarker: dmSecondaryColorDarker
    },
    overrides: {
        headerRoot: css`
            background: ${basicWhite};
            border-bottom: ${basicWhite};
        `
    }
};

export const colorlessThemeDark = merge(
    {},
    defaultThemeDark,
    colorlessThemeLightOverrides,
    colorlessThemeDarkOverrides
);