import React from "react";
import {
    Index,
    connectStateResults
  } from "react-instantsearch-dom";

import {
    HitsWrapper,
    PoweredBy,
    ResultsHeader,
    StatText,
    IndicieHeader,
    StyledHits as Hits,
    SearchControl,
    SearchInput,
    CancelButton
} from "./search-styles";

import Input from "../input";

import * as hitComponents from "../hit-components";
import { searchIndices } from '../../../providers/search-indices.const';
import { ApplicationState } from "../../../providers/application-state.interface";
import { searchFocusActionTypes } from '../../../providers/reducers/searchFocus.reducer';
import withApplicationState from "../../_hocs/with-application-state";
import { ITrackingService } from '../../../services/tracking.service';

const Results = connectStateResults(
    ({ searchState: state, searchResults: res, children }) =>
      res && res.nbHits > 0 ? children : <StatText>{ `No results for '${state.query}'`}</StatText>
  )

const Stats = connectStateResults(
({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
)

interface SearchProps {
    collapse: any;
    hitsAsGrid: any;
    cancelSearch: any;
    state: ApplicationState;
    dispatch: ({ type }: { type: string; payload?: any; }) => void;
    trackingService: ITrackingService;
}

const Search = (props: SearchProps) => {
    const { collapse, hitsAsGrid, cancelSearch, state, dispatch } = props;
    return(
        <>
            <SearchControl>
                <SearchInput>
                    <Input
                        onFocus={() => dispatch({ type: searchFocusActionTypes.SET_FOCUS, payload: true })}
                        trackingService={props.trackingService}
                        {...{ collapse, focus: state.searchFocus }}
                    />
                </SearchInput>
                <CancelButton onClick={() => cancelSearch()}>Cancel</CancelButton>
            </SearchControl>
            <HitsWrapper show={state.searchQuery && state.searchQuery.length > 0 } asGrid={hitsAsGrid}>
                { searchIndices.map(({ name, title, hitComp }) => (
                    <Index key={name} indexName={name}>
                        <IndicieHeader>
                            <ResultsHeader>{title}</ResultsHeader>
                            <StatText><Stats /></StatText>
                        </IndicieHeader>
                        <Results>
                            <Hits hitComponent={hitComponents[hitComp](() => dispatch({ type: searchFocusActionTypes.SET_FOCUS, payload: false }))} />
                        </Results>
                    </Index>
                ))}
                <PoweredBy />
            </HitsWrapper>
        </>
    );
};

export default withApplicationState(Search);