import styled from 'styled-components';
import { leftAccentColorLighter, primaryColorDarker } from '../../../styles/_theme-variables';

const barBg = primaryColorDarker;
const barFill = leftAccentColorLighter;

export const  Progress  =  styled.div`
  position:  fixed;
  background:  linear-gradient(
    to right,
    ${barFill} ${props => props.scroll},
    ${barBg}  0);
  width:  100%;
  height:  4px;
  z-index:  3;
`;