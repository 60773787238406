import { Cookies } from "react-cookie-consent";

declare global {
    interface Window { analytics: any; }
}

export interface ITrackingService {
    TrackEvent(event: any)
}

export interface TrackingEvent {
    name: string
    payload: any
}

export class TrackingService implements ITrackingService {
    constructor() {    
    }    

    TrackEvent(event: TrackingEvent) {
        const consented = Cookies.get('CookieConsent');
        if (consented && window.analytics) {
            window.analytics.track(event.name, {
                payload: event.payload
            });    
        }
    }
}