import styled from "styled-components";
import { articleFont } from "../../../styles/_mixins";
import { leftAccentColorLight, basicWhite } from '../../../styles/_theme-variables';
import { fluidFontSizes } from '../../../styles/_calculations';

const hitColor = leftAccentColorLight;
const hitColorHover = basicWhite;

export const Root = styled.div`
    & a {
        color: ${hitColor};
        &:hover {
            color: ${hitColorHover};
        }
    }
`;

export const Item = styled.h4`
    ${articleFont};
    ${fluidFontSizes.bodySize};
    margin-bottom: 0.5em;
    color: ${hitColor};
    font-weight: 400;
    &:hover {
        color: ${hitColorHover};
    }
`;