import { StateAction } from '../state-action.interface';

export const isExpandedActionTypes = {
    EXPAND: 'EXPAND',
    COLLAPSE: 'COLLAPSE'
}

export function isExpandedReducer(state: boolean = false, action: StateAction): boolean {
    switch(action.type) {
        case isExpandedActionTypes.EXPAND: {
            return true;
        }
        case isExpandedActionTypes.COLLAPSE: {
            return false
        }
        default:
            return state;
    }
}