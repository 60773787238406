import styled, { css } from "styled-components";
import { borderRadius, backgroundPrimary, textSecondary } from '../../../styles/_theme-variables';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { articleFont, transitionColor, transitionTime } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';

export const Root = styled.a`
    ${fluidFontSizes.bodySize}
    padding: 0.75em;
    width: auto;
    text-align: center;
    display: inline-block;
    border-radius: ${borderRadius};
    text-decoration: none;
    background-color: ${backgroundPrimary};
    color: ${textSecondary};
    transition: background-color ${transitionTime} ease-in, color ${transitionTime} ease-in;
    border: 1px solid ${textSecondary};

    &:hover {
        background-color: ${textSecondary};
        color: ${backgroundPrimary};
    }
`;

const IconLinkStyles = css`
    ${fluidFontSizes.bodySize}
    margin-right: 0.5em;
`

const TextStyles = css`
    ${articleFont};
    ${fluidFontSizes.bodySize}
    color: ${textSecondary};
    text-decoration: none;
    ${transitionColor};
`

export const Icon = styled(FontAwesomeIcon)`
    ${IconLinkStyles}
`;
