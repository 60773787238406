import { css } from "styled-components";
import { headerFont, bpMobilePhablet, headerSize, mobileHeaderSize } from "./_theme-variables";

export const transitionTime = `300ms`;

export const transitionColor = css`
    transition: color ${transitionTime} ease-in;
`;

export const transitionBackgroundColor = css`
    transition: background-color ${transitionTime} ease-in;
`;

export const transitionBackground = css`
    transition: background ${transitionTime} ease-in;
`;

export const transitionBorder = css`
    transition: border ${transitionTime} ease-in;
`;

export const transitionBoxShadow = css`
    transition: box-shadow ${transitionTime} ease-in;
`;

export const transitionTransform = css`
    transition: transform ${transitionTime} ease-in;
`;

export const transitionAll = css`
    transition: all ${transitionTime} ease-in;
`;

export const subHeaderFont = css`
    line-height: 1.1;
    font-family: ${props => props.theme.styles.font.headerFont};

    @media screen and (min-width: ${props => props.theme.styles.queries.bpMobilePhablet}) {
        font-size: ${props => props.theme.styles.font.subHeaderSize};
    }

    @media screen and (max-width: ${props => props.theme.styles.queries.bpMobilePhablet}) {
        font-size: ${props => props.theme.styles.font.mobileSubHeaderSize};
    }
`;

export const articleFont = css`
    font-family: ${props => props.theme.styles.font.proseFont};
    line-height: 1.4;
`;

export const headerFontMixin = css`
    line-height: 0.9;
    font-family: ${headerFont};
    font-weight: 400;
`;