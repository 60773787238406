import merge from 'lodash.merge';


// #####  Color Palette by Paletton.com
// #####  Palette URL: http://paletton.com/#uid=53F0u0kxSD2k6IIpLELDas0FOlT


// *** Primary color:

//    shade 0 = #1056BE = rgb( 16, 86,190) = rgba( 16, 86,190,1) = rgb0(0.063,0.337,0.745)
//    shade 1 = #5887CD = rgb( 88,135,205) = rgba( 88,135,205,1) = rgb0(0.345,0.529,0.804)
//    shade 2 = #346EC3 = rgb( 52,110,195) = rgba( 52,110,195,1) = rgb0(0.204,0.431,0.765)
//    shade 3 = #0A4193 = rgb( 10, 65,147) = rgba( 10, 65,147,1) = rgb0(0.039,0.255,0.576)
//    shade 4 = #073373 = rgb(  7, 51,115) = rgba(  7, 51,115,1) = rgb0(0.027,0.2,0.451)

// *** Secondary color (1):

//    shade 0 = #3217C4 = rgb( 50, 23,196) = rgba( 50, 23,196,1) = rgb0(0.196,0.09,0.769)
//    shade 1 = #715FD2 = rgb(113, 95,210) = rgba(113, 95,210,1) = rgb0(0.443,0.373,0.824)
//    shade 2 = #513CC8 = rgb( 81, 60,200) = rgba( 81, 60,200,1) = rgb0(0.318,0.235,0.784)
//    shade 3 = #240F9B = rgb( 36, 15,155) = rgba( 36, 15,155,1) = rgb0(0.141,0.059,0.608)
//    shade 4 = #1B0A79 = rgb( 27, 10,121) = rgba( 27, 10,121,1) = rgb0(0.106,0.039,0.475)

// *** Secondary color (2):

//    shade 0 = #00BA96 = rgb(  0,186,150) = rgba(  0,186,150,1) = rgb0(0,0.729,0.588)
//    shade 1 = #4BCAB2 = rgb( 75,202,178) = rgba( 75,202,178,1) = rgb0(0.294,0.792,0.698)
//    shade 2 = #25BFA1 = rgb( 37,191,161) = rgba( 37,191,161,1) = rgb0(0.145,0.749,0.631)
//    shade 3 = #008F73 = rgb(  0,143,115) = rgba(  0,143,115,1) = rgb0(0,0.561,0.451)
//    shade 4 = #006F5A = rgb(  0,111, 90) = rgba(  0,111, 90,1) = rgb0(0,0.435,0.353)


// #####  Generated by Paletton.com (c) 2002-2014

// *** The colors

const primaryColor = '#1056be';
const primaryColorDark = '#0A4193';
const primaryColorDarker = '#073373';
const primaryColorLight = '#346EC3';
const primaryColorLighter = '#5887CD'

const leftAccentColor = '#00BA96';
const leftAccentColorDark = '#008F73';
const leftAccentColorDarker = '#006F5A';
const leftAccentColorLight = '#25BFA1';
const leftAccentColorLighter = '#71f2d9';

const rightAccentColor = '#3217C4';
const rightAccentColorDark = '#240F9B';
const rightAccentColorDarker = '#1B0A79';
const rightAccentColorLight = '#513CC8';
const rightAccentColorLighter = '#715FD2';

const basicBlack = 'rgb(26,26,26)';
const basicWhite = '#f8fafa';
const darkGrey = 'rgb(51,51,51)';
const grey = 'rgb(245, 245, 245)';

export const defaultThemeLight = {
    name: 'default',
    color: {        
        primaryColor,
        primaryColorLight,
        primaryColorLighter,
        primaryColorDark,
        primaryColorDarker,

        leftAccentColor,
        leftAccentColorLight,
        leftAccentColorLighter,
        leftAccentColorDark,
        leftAccentColorDarker,

        rightAccentColor,
        rightAccentColorLight,
        rightAccentColorLighter,
        rightAccentColorDark,
        rightAccentColorDarker,

        basicBlack,
        basicWhite,
        darkGrey,
        grey,

        backgroundPrimary: basicWhite,
        backgroundSecondary: leftAccentColorLighter,
        textPrimary: basicBlack,
        textSecondary: primaryColorDarker
    },
    size: {
        spacingRaw: 1.6,
        spacing: `1.6rem`,
        spacingQuarter: `0.4rem`,
        spacingHalf: `0.8rem`,
        spacingDouble: `3.2rem`,
        borderRadius: `3px`
    },
    font: {
        headerFont: `'Palanquin Dark', sans-serif`,
        proseFont: `'Nunito', sans-serif`,
        headerSize: `3.2rem`,
        mobileHeaderSize: `2.6rem`,
        subHeaderSize: `2.2rem`,
        mobileSubHeaderSize: `1.8rem`
    },
    queries: {
        bpPhabletTablet: `60em`,
        bpMobilePhablet: `50em`
    }
};

const defaultDarkOverrides = {
    color: {
        backgroundPrimary: basicBlack,
        backgroundSecondary: primaryColorDarker,
        textPrimary: basicWhite,
        textSecondary: leftAccentColorLighter
    }
}

export const defaultThemeDark = merge({}, defaultThemeLight, defaultDarkOverrides);
