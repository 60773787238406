import { defaultThemeLight, defaultThemeDark } from "./_theme-default";
import { hotRodThemeLight, hotRodThemeDark } from "./_theme-hotrod";
import { newretroThemeLight, newretroThemeDark } from "./_theme-newretro";
import { colorlessThemeLight, colorlessThemeDark } from "./_theme-colorless";


export const resolveTheme = (themeKey, isDarkMode) => {

    const lightThemes = {
        'blank': colorlessThemeLight,
        'default': defaultThemeLight,
        'hotrod': hotRodThemeLight,
        'newretro': newretroThemeLight
    };

    const darkThemes = {
        'blank': colorlessThemeDark,
        'default': defaultThemeDark,
        'hotrod': hotRodThemeDark,
        'newretro': newretroThemeDark
    }

    const themes = isDarkMode ? darkThemes : lightThemes;

    const selectedTheme = themes[themeKey];

    return selectedTheme || themes['blank'];
}

export const themes = [
    'default',
    'hotrod',
    'newretro',
    'blank'
    // 'readeasy',
    // 'itsallcode',
    // 'cap',
    // 'funtendo',
    // 'megagen',
    // 'pokemall',
    // 'theme98',
    // 'themeFluid',
    // 'materials',
    // 'ameega',
    // 'zxtra',
    // 'teletext',
];