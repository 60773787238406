import styled, {css } from 'styled-components';
import { Search } from "styled-icons/fa-solid/Search";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import config from "../../../components/config";
import { headerFontMixin, articleFont, subHeaderFont } from '../../../styles/_mixins';
import { fluidFontSizes } from '../../../styles/_calculations';
import { primaryColorDarker, primaryColorDark, leftAccentColorLighter } from '../../../styles/_theme-variables';
import { GatsbyLink } from '../../_molecules/navmenu/navmenu-styles';

const gradientColor1 = primaryColorDark;
const gradientColor2 = primaryColorDarker;
const textColor = leftAccentColorLighter;

const { barHeightMobile, barHeightDesktop } = config;
const bpDesktop = 57;

const mobileGrid_default = css`
    grid-template-columns: 76px 1fr 76px;
    grid-template-rows: ${barHeightMobile}px auto;
    grid-template-areas:
        "mobileControls text controls"
        "navigation navigation navigation"
        "searchresults searchresults searchresults"
        "settings settings setting";
`;

const desktopGrid_default = css`
    grid-template-columns: 1fr 1fr 90px;
    grid-template-rows: ${barHeightDesktop}px auto;
    grid-template-areas:
        "text navigation controls"
        "searchresults searchresults searchresults"
        "settings settings settings";
`;

const desktopExpandedStyles = css`
    @media screen and (min-width: ${bpDesktop}em) {
        height: 100%;
        max-height: 100vh;
    }
`;

const mobileExpandedStyles = css`
    @media screen and (max-width: ${bpDesktop}em) {
        max-height: 100vh;
    }
`;

interface RootProps {
    isExpanded: boolean;
    searchModeEnabled: boolean;
    settingsModeEnabled: boolean;
}

export const Root = styled.div`
    width: 100%;
    display: grid;
    background: linear-gradient(110deg, ${gradientColor1} 60%, ${gradientColor2} 60%);

    max-height: ${barHeightMobile}px;
    @media screen and (min-width: ${bpDesktop}em) {
        max-height: ${barHeightDesktop}px;
    }
    overflow: hidden;
    transition: max-height 0.5s ease-in;

    ${(props: RootProps) => ((props.searchModeEnabled || props.settingsModeEnabled) && desktopExpandedStyles)}
    ${(props: RootProps) => (props.searchModeEnabled || props.settingsModeEnabled || props.isExpanded) && mobileExpandedStyles}

    ${mobileGrid_default}

    @media screen and (min-width: ${bpDesktop}em) {
        ${desktopGrid_default}
    }
`;

export const MobileControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: mobileControls;

    @media screen and (min-width: ${bpDesktop}em) {
        display: none;
    }
`;

export const Text = styled.div`
    grid-area: text;
    display: flex;

    @media screen and (min-width: ${bpDesktop}em) {
        align-items: center;
        padding: 0.5em;
    }
`;

interface NavigationProps {
    isExpanded: boolean;
    searchModeEnabled: boolean;
    settingsModeEnabled: boolean;
}

const hiddenNavigationStyles = css`
    @media screen and (max-width: ${bpDesktop}em) {
        display: none;
    }
`;

export const Navigation = styled.div`
    grid-area: navigation;

    ${(props: NavigationProps) => (props.searchModeEnabled || props.settingsModeEnabled) && hiddenNavigationStyles}
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: ${bpDesktop}em) {
        justify-content: center;
        max-height: calc(100vh - ${barHeightMobile}px);
        @media screen and (min-width: ${bpDesktop}em) {
            max-height: calc(100vh - ${barHeightDesktop}px);
        }
    }
`;

export const SearchResults = styled.div`
    grid-area: searchresults;

    overflow: auto;
    ${fluidFontSizes.smallPrintSize}
    padding: 1em 0.5em;
    max-height: calc(100vh - ${barHeightMobile}px);
    @media screen and (min-width: ${bpDesktop}em) {
        max-height: calc(100vh - ${barHeightDesktop}px);
    }
`;

export const Controls = styled.div`
    align-items: center;
    grid-area: controls;
    display: flex;
    justify-content: flex-end;
    ${fluidFontSizes.smallPrintSize};
    padding-right: 0.5em;
`;

export const Settings = styled.div`
    grid-area: settings;

    max-height: calc(100vh - ${barHeightMobile}px);
    @media screen and (min-width: ${bpDesktop}em) {
        max-height: calc(100vh - ${barHeightDesktop}px);
    }
    overflow: auto;

    ${fluidFontSizes.smallPrintSize};
    padding: 0.5em;
    color: ${textColor};
`;

export const SettingsHeader = styled.h3`
    ${fluidFontSizes.header6Size};
    ${subHeaderFont};
    color: ${textColor};
    line-height: 0.9;
    font-weight: 400;
    margin-bottom: 0.5em;
    text-align: center;
`;

export const Setting = styled.div`
    display: flex;
    ${fluidFontSizes.bodySize};
    padding: 0 0.5em;
    margin-bottom: 0.5em;
`;

export const TextContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: ${bpDesktop}em) {
        width: 100%;
        ${fluidFontSizes.smallPrintSize};
        padding: 0.4em;
    }
`;

export const Link = styled(GatsbyLink)`
    text-decoration: none;
`;

export const HeaderText = styled.h1`
    ${headerFontMixin};
    ${fluidFontSizes.header6Size};    
    letter-spacing: 0.25px;

    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 0.3em;

    ${Link} & {
        color: ${textColor};
    }
    
    @media screen and (min-width: ${bpDesktop}em) {
        text-align: left;
        display: inline-block;
        width: auto;
        margin: 0;
    }
`;

export const SubheaderText = styled.h2`
    ${articleFont};
    ${fluidFontSizes.smallPrintSize};
    letter-spacing: 0.25px;
    font-weight: 400;
    color: ${textColor};

    display: block;
    text-align: center;
    width: 100%;

    @media screen and (min-width: ${bpDesktop}em) {
        margin-left: 0.5em;
        text-align: left;
        display: inline-block;
        width: auto;

        &:before {
            content: '|';
            margin-right: 0.5em;
        }
    }
`;

interface BurgerButtonProps {
    isExpanded: boolean;
}

export const BurgerButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 45px;
    height: 45px;
    background: transparent;
    border: none;
    cursor: pointer;

    ${fluidFontSizes.smallPrintSize};
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

    &:focus {
        outline: none;
    }

    div {
        width: 26px;
        height: 0.25rem;
        background: ${textColor};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${(props: BurgerButtonProps) => props.isExpanded ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${(props: BurgerButtonProps) => props.isExpanded ? '0' : '1'};
            transform: ${(props: BurgerButtonProps) => props.isExpanded ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${(props :BurgerButtonProps) => props.isExpanded ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`;

export interface ControlButtonProps {
    active: boolean;
}

export const ControlButton = styled.button`
    width: 38px;
    height: 45px;
    padding: 5px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: ${(props: ControlButtonProps) => props.active ? 1 : 0.5};
    transition: opacity 200ms ease-in;

    &:hover {
        opacity: 1;
    }

    &:focus {
        outline: none;
    }
`;

export const SearchIcon = styled(Search)`
    ${fluidFontSizes.smallPrintSize}
    width: 0.8em;
    pointer-events: none;
    color: ${textColor};
    margin-left: auto;
    margin-right: auto;
    width: 1.3em;
    display: block;
`;

export const SettingsIcon = styled(FontAwesomeIcon)`
    ${fluidFontSizes.smallPrintSize}
    pointer-events: none;
    color: ${textColor};
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 25px;
`;