import { StateAction } from '../state-action.interface';
export const searchActionTypes = {
    SET_QUERY: 'SET_QUERY'
}

export default function searchReducer(state: string = '', action: StateAction): string {
    switch(action.type) {
        case searchActionTypes.SET_QUERY: {
            return action.payload;
        }
        default:
            return state;
    }
}