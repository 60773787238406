import React from "react"
import styled from "styled-components"
import { Algolia } from "styled-icons/fa-brands/Algolia"
import { leftAccentColorLighter } from '../../../styles/_theme-variables';
import { articleFont, subHeaderFont } from '../../../styles/_mixins';
import { Hits } from "react-instantsearch-dom";
import { fluidFontSizes } from '../../../styles/_calculations';

const textColor = leftAccentColorLighter;

export const HitsWrapper = styled.div`
  display: ${(props: any) => (props.show ? `grid` : `none`)};
  ${fluidFontSizes.smallPrintSize}
  padding: 0.5em;
  background: transparent;
`;

export const ResultsHeader = styled.h3`
  ${subHeaderFont}
  ${fluidFontSizes.header5Size};
  color: ${textColor};
  margin-bottom: 0.5em;
`;

export const IndicieHeader = styled.header`
  display: flex;
`;

export const StatText = styled.p`
  ${articleFont};
  ${fluidFontSizes.bodySize};
  color: ${textColor};
  padding: 0.5em;
`;

const PoweredByRoot = styled.span`
  ${articleFont}
  color: ${textColor};

  & a {
    color: ${textColor};
  }
`;

export const StyledHits = styled(Hits)`
  & li {
    ${fluidFontSizes.bodySize};
    margin-bottom: 1em;
  }
`;

export const SearchControl = styled.div`
  display: flex;
  padding: 0.5em;
`;

export const SearchInput = styled.div`
  flex: 1;
  margin-right: 1em;
`;

export const CancelButton = styled.button`
  background: transparent;
  color: ${textColor};
  border: 1px solid ${textColor};
  border-radius: 2px;
  cursor: pointer;
`;

export const PoweredBy = () => (
  <PoweredByRoot>
    Powered by{` `}
    <a href="https://algolia.com" target="__blank">
      <Algolia size="1em" /> Algolia
    </a>
  </PoweredByRoot>
);
