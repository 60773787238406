import React from "react";
import { ThemeProvider } from "styled-components";
import { FeatureToggleProvider } from "react-feature-toggles/lib";

import { toggles } from "../features";
import { resolveTheme } from "../styles/themeResolver";
import ThemeContext from "../context/theme-context";
import { colorlessThemeLight,colorlessThemeDark } from "../styles/_theme-colorless";

const App = ({ children }) => {
    return (
        <ThemeContext.Consumer>
            {
                theme => (
                    <ThemeProvider theme={
                        {
                            styles: theme ? resolveTheme(theme.themeKey, theme.isDarkMode) : (theme.isDarkMode ? colorlessThemeDark : colorlessThemeLight),
                            key: theme.themeKey,
                            darkMode: theme.isDarkMode
                        }
                    }>
                        <FeatureToggleProvider featureToggleList={toggles}>
                            { children }
                        </FeatureToggleProvider>
                    </ThemeProvider>    
                )
            }
        </ThemeContext.Consumer>
    );   
};

export default App;
