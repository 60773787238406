import React from "react";

import { resolveIcon } from "../../../framework/icon-resolver";

import { Root, Item, NavigationLink, Icon } from "./sociallinks-styles";

const mapItem = ({ type, link }) => (
        <NavigationLink
            href={ link }
            target="_blank"
            rel="noopener noreferrer"
        >
            <Icon
                icon={resolveIcon(type)}
            />{ type }
        </NavigationLink>
);

const SocialLinks = ({ keyprefix, items }) => (
    <Root>
        { items.filter(i => i.link).map(i =>
            <Item key={`${keyprefix}sociallinks-${i.type}`}>
                { mapItem(i) }
            </Item>
        )}
    </Root>
);

export default SocialLinks;
