import * as React from 'react';
import algoliasearch from "algoliasearch";
import { InstantSearch } from "react-instantsearch-dom";

import { Root } from "./instantsearch-provider-styles";
import { searchIndices } from "./search-indices.const";
import { ApplicationState } from './application-state.interface';
import { searchFocusActionTypes } from './reducers/searchFocus.reducer';
import { searchActionTypes } from './reducers/search.reducer';
import withApplicationState from '../components/_hocs/with-application-state';

interface InstantSearchProviderProps {
    state: ApplicationState;
    dispatch: ({ type }: { type: string; payload?: any; }) => void;
    children: any
}

function useOnClickOutside(ref, handler) {
    React.useEffect(
        () => {
            const listener = event => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        },
        [ref, handler]
    );
}

function InstantSearchProvider(props: InstantSearchProviderProps) {
    const ref = React.createRef();

    const searchClient = React.useMemo(
        () =>
          algoliasearch(
            process.env.GATSBY_ALGOLIA_APP_ID,
            process.env.GATSBY_ALGOLIA_SEARCH_KEY
          ),
        []
    )

    useOnClickOutside(ref, () => props.dispatch({ type: searchFocusActionTypes.SET_FOCUS, payload: false }))

    return (
        <>
            <InstantSearch
                searchClient={searchClient}
                indexName={searchIndices[0].name}
                onSearchStateChange={({ query }) => {
                    props.dispatch({ type: searchActionTypes.SET_QUERY, payload: query })
                }}
                root={{ Root, props: { ref } }}
            >
                { props.children }
            </InstantSearch>
        </>
    )
}

export default withApplicationState(InstantSearchProvider)