import React from "react";
import { Progress } from './scroll-bar-styles';

interface ScrollBarProps {
    scroll: number;
}

const ScrollBar = (props: ScrollBarProps) => (
    <Progress scroll={`${props.scroll}%`} />
);

export default ScrollBar;
