import styled from "styled-components";
import config from "../config";
import { spacing, spacingHalf, headerFont, textSecondary, textPrimary, backgroundSecondary } from '../../styles/_theme-variables';
import { transitionColor, transitionBorder, articleFont } from '../../styles/_mixins';

import { fluidFontSizes } from '../../styles/_calculations';

export const CenterWrapper = styled.div`
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: 64em) {
        max-width: 70vw;
    }
`;

export const HeaderWrapper = styled.div`
    padding: 0;
`;

export const Header = styled.h2`
    /* font-family: ${headerFont};
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: ${spacingHalf};
    color: ${textSecondary};
    ${transitionColor} */
`;

export const Separator = styled.span`
    &:before {
        content: '';
        width: 100%;
        max-width: 12.5rem;
        display: block;

        ${fluidFontSizes.bodySize};
        margin: 0.5em auto;

        border-top: 1px solid ${textSecondary};
        ${transitionBorder}
        opacity: 0.3;   
    }
`;

export const HeroWrapper = styled.div`
    margin-bottom: ${spacing};
`;

export const Tags = styled.ul`
    list-style: none;
    display: inline-block;
`;

export const Tag = styled.li`
    display: inline-block;
    margin: 0.25em;    
`;

export const TagButton = styled.button`
color: ${textPrimary};
    background-color: ${backgroundSecondary};
    ${articleFont}
    ${fluidFontSizes.smallPrintSize}
    padding: 0.25em;
    border: none;
    border-radius: 2px;
    cursor: pointer;
`;
