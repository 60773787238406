import React, { useEffect, useState } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { Form, Text } from "./styled";
import useDebounce from '../../../framework/use-debounce';

const Input = ({ refine, trackingService, ...rest }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      if (debouncedSearchTerm) {
        trackingService.TrackEvent({
            name: 'Search Term Updated',
            payload: {
                term: debouncedSearchTerm
            }
        });
        refine(debouncedSearchTerm);
      } else {
        refine('');
      }
    },
    [debouncedSearchTerm]
  );

  function searchUpdated(newValue: string) {
    setSearchTerm(newValue);
  }

  return (
    <Form>
      <Text
        type="text"
        placeholder="Search"
        aria-label="Search"
        onChange={e => searchUpdated(e.target.value)}
        {...rest}
      />
    </Form>
  );
}

export default connectSearchBox(Input);
