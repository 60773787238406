import React from "react";
import ThemeContext from "../../../context/theme-context";

import {
    Root,
    Left,
    Right,
    Text,
    Markdown
} from "./footer-styles";

const FooterMarkdown = ({ markdown }) => (
    <Markdown
        className="c-footer__text" source={markdown}    
        renderers={{link: props => <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>}}
    />
);

const Footer = ({
    copyright,
    hostingNotice,
    cdnNotice,
    sitemapLink
}) => (
    <ThemeContext.Consumer>
      {
        theme => (
            <Root>
                <Left>
                    <Text>© {copyright}{new Date().getFullYear()}</Text>
                    { sitemapLink && <Text><a href={sitemapLink}>Sitemap</a></Text> }                
                </Left>
                <Right>
                    <Text>Built with{` `}<a href="https://www.gatsbyjs.org" target="_blank" rel="noopener noreferrer">Gatsby</a></Text>    
                    <FooterMarkdown markdown={hostingNotice} />
                    <FooterMarkdown markdown={cdnNotice} />
                </Right>
            </Root>
        )
      }
      </ThemeContext.Consumer>
);

export default Footer;