/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import CookieConsent from "react-cookie-consent";

import App from "./app";
import Sidebar from "../components/_organisms/sidebar";
import { CenterWrapper } from "../components/primitives";
import Footer from "../components/_organisms/footer";
import { TrackingService } from '../services/tracking.service';

import {
  GlobalStyle,
  Root,
  HeaderWrapper,
  SidebarWrapper,
  MainWrapper,
  FooterWrapper,
  Main
} from "./styled";

import "./layout.scss";
import Menu from "./_organisms/menu";

const trackingService = new TrackingService();

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      contact: jsonJson(name: {eq: "contact"}) {
        email {
          link
          label
          address
        }
        social {
          type
          link
        }
      }
      layout: jsonJson(name: {eq: "layout"}) {
        name
        content {
          header
          subheader
          pic {
            name
            src {
              childImageSharp {
                fixed(width: 140, height: 140, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed
                }                
              }
            }
          }
          navigation {
            name
            path
            internal
          }
          copyright
          blurb {
            html
          }
          contact {
            html
          }
          hostingNotice
          cdnNotice
          sitemap {
            link
          }
        }
      }
    }
  `)

  return (
    <App>
      <GlobalStyle />
      <HeaderWrapper>
        <Menu
          header={data.layout.content.header}
          subheader={data.layout.content.subheader}
          navigation={data.layout.content.navigation}
          trackingService={trackingService}
        />
      </HeaderWrapper>
      <Root>
        <MainWrapper>
          <Main
            className="c-layout__main"
          >
            {children}
          </Main>
        </MainWrapper>
        <SidebarWrapper>
          <CenterWrapper>
            <Sidebar
              pic={data.layout.content.pic}
              blurb={data.layout.content.blurb}
              socialLinks={data.contact.social}
              email={data.contact.email}
              contact={data.layout.content.contact}
            />
          </CenterWrapper>
        </SidebarWrapper>
        <FooterWrapper>
        <Footer
            copyright={data.layout.content.copyright}
            hostingNotice={data.layout.content.hostingNotice}
            cdnNotice={data.layout.content.cdnNotice}
            sitemapLink={data.layout.content.sitemap.link}
          />
        </FooterWrapper>
        <CookieConsent enableDeclineButton style={{fontFamily: "'Nunito',sans-serif"}} declineButtonStyle={{fontFamily: "'Palanquin Dark',sans-serif"}} buttonStyle={{background: "#71f2d9", color:"#0A4193", fontFamily: "'Palanquin Dark',sans-serif"}}>
            This website uses cookies to enhance the user experience. These cookies are used for analytics purposes. If you decline, analytics will not be gathered when you visit this website. A single cookie will be used in your browser to remember your preference, not to be tracked.
        </CookieConsent>
      </Root>
    </App>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout