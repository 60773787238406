import { ApplicationState } from './application-state.interface';
import { StateAction } from './state-action.interface';

import { isExpandedReducer } from './reducers/is-expanded.reducer';
import { userModeReducer } from './reducers/user-mode.reducer';
import searchFocusReducer from './reducers/searchFocus.reducer';
import searchReducer from './reducers/search.reducer';

export default function rootReducer(state: ApplicationState, action: StateAction): ApplicationState {
    const { isExpanded, userMode, searchQuery, searchFocus } = state;

    return {
        isExpanded: isExpandedReducer(isExpanded, action),
        userMode: userModeReducer(userMode, action),
        searchQuery: searchReducer(searchQuery, action),
        searchFocus: searchFocusReducer(searchFocus, action)
    }
}