import styled, { css } from "styled-components";
import { Link } from "gatsby"

import { bpMobilePhablet, basicWhite } from '../../../styles/_theme-variables';
import { articleFont } from "../../../styles/_mixins";
import { fluidFontSizes } from "../../../styles/_calculations";
import { leftAccentColorLighter } from '../../../styles/_theme-variables';

const ItemColor = leftAccentColorLighter;
const ItemHoverColor = basicWhite;

export const Root = styled.ul`
    list-style: none;

    @media screen and (max-width: ${bpMobilePhablet}) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

export const Item = styled.li`
    ${fluidFontSizes.smallPrintSize}
    color: ${ItemColor};
    margin: 0 0.5em;
        
    display: inline-block;

    @media screen and (max-width: ${bpMobilePhablet}) {
        text-align: center;
        margin: 0.5em 0;
        flex-basis: 50%;
    }
`;

const LinkStyle = css`
    ${articleFont};
    ${fluidFontSizes.smallPrintSize}
    text-decoration: none;
    color: ${ItemColor};
    padding: 0.8em 0.3em;

    @media screen and (max-width: ${bpMobilePhablet}) {
        ${fluidFontSizes.bodySize}
    }

    &:hover {
        color: ${ItemHoverColor};
    }
`;

export const GatsbyLink = styled(Link)`
    ${LinkStyle}
`

export const RegularLink = styled.a`
    ${LinkStyle}
`;
