// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-index-js": () => import("/home/vsts/work/1/s/src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-page-index-js": () => import("/home/vsts/work/1/s/src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-blog-landing-index-js": () => import("/home/vsts/work/1/s/src/templates/blogLanding/index.js" /* webpackChunkName: "component---src-templates-blog-landing-index-js" */),
  "component---src-templates-project-index-tsx": () => import("/home/vsts/work/1/s/src/templates/project/index.tsx" /* webpackChunkName: "component---src-templates-project-index-tsx" */),
  "component---src-templates-preview-index-tsx": () => import("/home/vsts/work/1/s/src/templates/preview/index.tsx" /* webpackChunkName: "component---src-templates-preview-index-tsx" */),
  "component---src-pages-404-js": () => import("/home/vsts/work/1/s/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("/home/vsts/work/1/s/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("/home/vsts/work/1/s/src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("/home/vsts/work/1/s/src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */)
}

