import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fluidFontSizes } from '../../../styles/_calculations';
import { leftAccentColorLighter } from '../../../styles/_theme-variables';

const iconLinkColor = leftAccentColorLighter;

export const Root = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0.5em;
`;

const IconLinkStyles = css`
    ${fluidFontSizes.smallPrintSize}
    width: 0.8em;
    color: ${iconLinkColor};
`

export const Icon = styled(FontAwesomeIcon)`
    ${IconLinkStyles}
    margin: 0 0.2em;
`;
