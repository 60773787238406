import { css } from "styled-components";
import merge from "lodash.merge";
import { transparentize, lighten, darken, desaturate } from "polished";
import { defaultThemeLight, defaultThemeDark } from "./_theme-default";

const basicBlack = `rgb(37, 37, 37)`;
const basicWhite = `rgb(255, 255, 255)`;
const primaryColor = `#AA0505`;
const secondaryColor = `#FBCA03`;

const primaryColorLighter = lighten(0.1, primaryColor);
const primaryColorLightest = lighten(0.5, primaryColor);
const primaryColorLightestDesaturated = desaturate(1, primaryColorLightest);
const primaryColorDarker = darken(0.1, primaryColor);
const primaryColorDarkest = darken(0.3, primaryColor);

const secondaryColorLighter = lighten(0.1, secondaryColor);
const secondaryColorLightest = lighten(0.5, secondaryColor);
const secondaryColorDarker = darken(0.1, secondaryColor);
const secondaryColorDarkest = darken(0.5, secondaryColor);

const textPrimary = basicBlack;
const textPrimaryTransparent = transparentize(0.15, textPrimary);
const textSecondary = primaryColor;
const backgroundPrimary = basicWhite;
const backgroundSecondary = primaryColorLightest;
const backgroundPrimaryTransparent = transparentize(0.15, backgroundPrimary);

const dmTextPrimary = basicWhite;
const dmTextSecondary = secondaryColor;
const dmBackgroundPrimary = basicBlack;
const dmBackgroundPrimaryTransparent = transparentize(0.15, dmBackgroundPrimary);
const dmBackgroundSecondary = primaryColor;

const hotRodThemeLightOverrides = {
    name: 'hotrod',
    color: {
        textPrimary,
        textPrimaryTransparent,
        textSecondary,  
        backgroundPrimary,        
        backgroundPrimaryTransparent,
        backgroundSecondary,
        primaryColor,
        primaryColorLighter,
        primaryColorLightest,
        primaryColorLightestDesaturated,
        primaryColorDarker,
        primaryColorDarkest,        
        secondaryColor,
        secondaryColorLighter,
        secondaryColorLightest,        
        secondaryColorDarker,
        secondaryColorDarkest
    },
    font: {
        headerFont: `'Russo One', sans-serif;`,
        proseFont: `'Open Sans', sans-serif`
    },
    overrides: {
        headerRoot: css`
            background: linear-gradient(110deg, ${primaryColorDarker} 30%, ${primaryColor} 65%);
        `
    }
};

export const hotRodThemeLight = merge({}, defaultThemeLight, hotRodThemeLightOverrides);

const hotRodThemeDarkOverrides = {
    color: {
        backgroundPrimary: dmBackgroundPrimary,
        backgroundPrimaryTransparent: dmBackgroundPrimaryTransparent,
        backgroundSecondary: dmBackgroundSecondary,
        textPrimary: dmTextPrimary,
        textSecondary: dmTextSecondary
    },
    font: {
        headerFont: `'Russo One', sans-serif;`,
        proseFont: `'Open Sans', sans-serif`
    },
    overrides: {
        headerRoot: css`
            background: linear-gradient(110deg, ${primaryColorDarker} 30%, ${primaryColor} 65%);
        `
    }
};

export const hotRodThemeDark = merge(
    {},
    defaultThemeDark,
    hotRodThemeLightOverrides,
    hotRodThemeDarkOverrides
);