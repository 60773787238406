const getProps = props => props;
const theme = props => {
    const result = getProps(props).theme.styles;
    // console.log(result);
    return result;
} ;

const selectThemeProps = (props, key) => theme(props)[key];

const font = props => selectThemeProps(props, 'font');
const size = props => selectThemeProps(props, 'size');
const color = props => selectThemeProps(props, 'color');
const queries = props => selectThemeProps(props, 'queries');

export const headerFont = props => font(props).headerFont;

// Size
export const spacing = props => size(props).spacing;
export const spacingQuarter = props => size(props).spacingQuarter;
export const spacingHalf = props => size(props).spacingHalf;
export const spacingDouble = props => size(props).spacingDouble;
export const borderRadius = props => size(props).borderRadius;

// Colors
export const primaryColor = props => color(props).primaryColor;
export const primaryColorDark = props => color(props).primaryColorDark;
export const primaryColorDarker = props => color(props).primaryColorDarker;
export const primaryColorLight = props => color(props).primaryColorLight;
export const primaryColorLighter = props => color(props).primaryColorLighter;

export const leftAccentColor = props => color(props).leftAccentColor;
export const leftAccentColorDark = props => color(props).leftAccentColorDark;
export const leftAccentColorDarker = props => color(props).leftAccentColorDarker;
export const leftAccentColorLight = props => color(props).leftAccentColorLight;
export const leftAccentColorLighter = props => color(props).leftAccentColorLighter;

export const rightAccentColor = props => color(props).rightAccentColor;
export const rightAccentColorDark = props => color(props).rightAccentColorDark;
export const rightAccentColorDarker = props => color(props).rightAccentColorDarker;
export const rightAccentColorLight = props => color(props).rightAccentColorLight;
export const rightAccentColorLighter = props => color(props).rightAccentColorLighter;

export const basicBlack = props => color(props).basicBlack;
export const basicWhite = props => color(props).basicWhite;
export const darkGrey = props => color(props).darkGrey;
export const grey = props => color(props).grey;

export const textPrimary = props => color(props).textPrimary;
export const backgroundPrimary = props => color(props).backgroundPrimary;
export const textSecondary = props => color(props).textSecondary;
export const backgroundSecondary = props => color(props).backgroundSecondary;

// Queries
export const bpPhabletTablet = props => queries(props).bpPhabletTablet;
export const bpMobilePhablet = props => queries(props).bpMobilePhablet;
export const bpMobile = props => queries(props).bpMobile;

// Font
export const headerSize = props => font(props).headerSize;
export const mobileHeaderSize = props => font(props).mobileHeaderSize;
export const subHeaderSize = props => font(props).subHeaderSize;
export const mobileSubHeaderSize = props => font(props).mobileSubHeaderSize;
