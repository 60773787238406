import React from "react";
import {
    Root,
    Item,
    GatsbyLink,
    RegularLink
} from "./navmenu-styles";

const mapItem = ({ name, path, internal }) => (
    <Item key={`navmenu-${name}`}>
        {
            internal
                ? <GatsbyLink to={ path }>{ name }</GatsbyLink>
                : <RegularLink href={ path }>{ name }</RegularLink>
        }
    </Item>
);

const NavMenu = ({ items, children }) => (
    <>
        <Root>
            { items.map(mapItem) }
        </Root>
        { children }
    </>
);

export default NavMenu;
