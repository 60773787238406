import styled from "styled-components"
import { fluidFontSizes } from "../../../styles/_calculations";
import { articleFont } from "../../../styles/_mixins";
import { leftAccentColor } from '../../../styles/_theme-variables';

const textColor = leftAccentColor;

export const Text = styled.input`
  color: ${textColor}  ;
  outline: none;
  border: none;
  ${articleFont}
  background: transparent;
  width: 100%;

  border: none;
  border-bottom: 1px solid ${textColor};
  border-radius: 2px;

  ${fluidFontSizes.bodySize};
  padding: 0.5em;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;