import React from "react";
import { resolveIcon } from "../../framework/icon-resolver";

import {
    Root,
    Icon,
    Selector
} from "./styled";

const ThemeSelector = ({ themeKey, setThemeKey, themes }) => {

    const handleChange = (event, setState) => {
        setState(event.target.value);
    }

    return (
        <Root>
            <Icon
                icon={resolveIcon('theme')}
            />
            <Selector value={themeKey} onChange={event => handleChange(event, setThemeKey)}>
                { themes.map(t => (<option value={t}>{t}</option>)) }
            </Selector>
        </Root>
    );
};

export default ThemeSelector;
